import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  AppBar,
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import headerBackground from "../../assets/images/headerBackground.png";
import KatchLogo from "../../assets/images/katch_logo";
import CustomButton from "../../components/button/custom-button.component";
import routes from "../../components/navigation/routes";
import PaddedView from "../../components/utils/padded-view.component";
import Spacer from "../../components/utils/spacer.component";
import { logout, resetUser } from "../../services/auth/auth-slice.service";
import { notificationSelector } from "../../services/notification/notification-slice.service";
import {
  getProfileDetail,
  profileSelector,
  resetProfile,
} from "../../services/profile/profile-slice.service";
import { getExpireAt, isLogin } from "../utils/index";
import NotificationTab from "./components/notification-tab.component";
import PhoneViewNotificationModal from "./components/phone-view-notification-modal";
import SearchBar from "./components/search-bars.component";
import Footer from "./footer.layout";
import SessionExpiredBox from "../../components/notification/session-expired-box.component";
import AccountRestorationBox from "../../components/notification/account-restoration-box.component";

const PCViewCenterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
}));

const AppBarContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  width: "100%",
  paddingTop: "10px",
}));

const MaxWidthBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.colors.bg.white,
  minHeight: "60vh",
}));

const OverflowBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "30px",
});

const ContentBox = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.colors.bg.white,
}));

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 18,
    minWidth: 160,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "5px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const AppBarTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "20px",
}));

const NotificationDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "12px",
  width: "12px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.notification,
  zIndex: 1,
  top: 5,
  right: 6,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

function CustomizedAppBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getProfileDetailObj } = useSelector(profileSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [avatarMenu, setAvatarMenu] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showAccountRestoration, setShowAccountRestoration] = useState(false);
  const { notificationIndexObj } = useSelector(notificationSelector);

  const handleOpenUserMenu = (event) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAvatarMenu(null);
  };

  const handleLogout = () => {
    dispatch(logout()).then(({ meta }) => {
      dispatch(resetProfile());
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        navigate(routes.LOGIN);
      }
    });
  };

  const renderAuthInfo = () => {
    if (isLogin()) {
      return (
        <>
          {isMobile && (
            <PhoneViewNotificationModal
              showModal={showNotification}
              setShowModal={setShowNotification}
            />
          )}

          <Grid item display="flex" alignItems="center">
            <CustomButton
              isLoading={getProfileDetailObj.status === "pending"}
              variant="text"
              textColor="white"
              buttonColor="white"
              endIcon={<AddCircleOutlinedIcon color="white" />}
              onClick={() => navigate(routes.CREDIT)}
              fontSize={theme.fonts.fontSizes.size16}
            >
              {getProfileDetailObj.status === "succeeded"
                ? `RM ${getProfileDetailObj.data.creditBalance}`
                : "RM 0.00"}
            </CustomButton>
          </Grid>
          <Grid item display="flex" alignItems="center" position="relative">
            <ClickAwayListener
              onClickAway={() => {
                if (!isMobile) setShowNotification(false);
              }}
            >
              <div>
                <IconButton onClick={() => setShowNotification(!showNotification)}>
                  <NotificationsIcon color="white" />
                  {notificationIndexObj.status === "succeeded" &&
                    notificationIndexObj.data.items.some((item) => item.read === false) && (
                      <NotificationDot />
                    )}
                </IconButton>
                {!isMobile && (
                  <Box
                    sx={{
                      visibility: showNotification ? "visible" : "hidden",
                      borderRadius: `${theme.shape.borderRadius[2]}px`,
                      border: "1px solid",
                      borderColor: theme.palette.colors.ui.border,
                      position: "absolute",
                      right: "0",
                      width: "350px",
                      backgroundColor: theme.palette.colors.bg.white,
                      zIndex: 99,
                      opacity: showNotification ? "1" : "0",
                      transition: "all 0.4s",
                    }}
                  >
                    <NotificationTab setShowNotification={setShowNotification} />
                  </Box>
                )}
              </div>
            </ClickAwayListener>
          </Grid>
          <Grid item display="flex">
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar
                src={
                  getProfileDetailObj.status === "succeeded"
                    ? getProfileDetailObj.data.imagePath
                    : null
                }
                sx={{
                  backgroundColor: theme.palette.colors.ui.secondary,
                  width: "30px",
                  height: "30px",
                }}
              />
            </IconButton>
            <StyledMenu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={avatarMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(avatarMenu)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key={1}
                onClick={() => {
                  handleCloseUserMenu();
                  navigate(routes.USERPROFILE);
                }}
              >
                <Typography textAlign="center">Account</Typography>
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  handleCloseUserMenu();
                  navigate(routes.MEMBERSHIPS);
                }}
              >
                <Typography textAlign="center">Memberships</Typography>
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  handleCloseUserMenu();
                  navigate(routes.PURCHASES);
                }}
              >
                <Typography textAlign="center">Purchases</Typography>
              </MenuItem>
              <MenuItem
                key={3}
                onClick={() => {
                  handleCloseUserMenu();
                  navigate(routes.PAYMENT_METHODS);
                }}
              >
                <Typography textAlign="center">Payment Methods</Typography>
              </MenuItem>
              <MenuItem key={4} onClick={() => handleLogout()}>
                <Typography textAlign="center">Sign Out</Typography>
              </MenuItem>
            </StyledMenu>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item>
          <CustomButton
            variant="contained"
            textColor="primary"
            buttonColor="white"
            onClick={() =>
              navigate(routes.LOGIN, { state: location.pathname === "/event" ? location : null })
            }
          >
            Sign In
          </CustomButton>
        </Grid>
        <Spacer position="left" />
        <Grid item>
          <CustomButton
            variant="text"
            textColor="white"
            buttonColor="white"
            onClick={() => navigate(routes.SIGNUP)}
          >
            Register
          </CustomButton>
        </Grid>
      </>
    );
  };

  const renderScreenOptionalAppBarContent = () => {
    switch (location.pathname) {
      case routes.HOME:
        return <SearchBar />;
      case routes.CREDIT:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Account Credits</AppBarTitle>
          </Grid>
        );
      case routes.MEMBERSHIPS:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Memberships</AppBarTitle>
          </Grid>
        );
      case routes.PAYMENT_METHODS:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Payment Methods</AppBarTitle>
          </Grid>
        );
      case routes.POINT:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Point Transactions</AppBarTitle>
          </Grid>
        );
      case routes.POINTEXPIRING:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Expiring Points</AppBarTitle>
          </Grid>
        );
      case routes.USERPROFILE:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Account</AppBarTitle>
          </Grid>
        );
      case routes.REFERRALS:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Referrals</AppBarTitle>
          </Grid>
        );
      case routes.PURCHASES:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Purchases</AppBarTitle>
          </Grid>
        );
      case routes.PRIVACYPOLICY:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Privacy Policy</AppBarTitle>
          </Grid>
        );
      case routes.CONTACT:
        return (
          <Grid item xs={12}>
            <AppBarTitle>Contact</AppBarTitle>
          </Grid>
        );
      default:
        return null;
    }
  };

  const renderSessionExpired = () => {
    if (moment(getExpireAt()).tz("Asia/Kuala_Lumpur").isBefore()) {
      setShowSessionExpired(true);
    }
  };

  useEffect(() => {
    if (isLogin())
      dispatch(getProfileDetail()).then(({ meta, error, payload }) => {
        if (meta.requestStatus === "rejected" && error.message === "Unauthenticated.") {
          setShowSessionExpired(true);
        }
        if (meta.requestStatus === "fulfilled") {
          if (payload.data.accountDeletion?.request) {
            setShowAccountRestoration(true);
          }
        }
      });
    renderSessionExpired();
  }, []);
  return (
    <>
      <SessionExpiredBox isShow={showSessionExpired} setIsShowAlert={setShowSessionExpired} />
      {getProfileDetailObj.status === "succeeded" && (
        <AccountRestorationBox
          isShow={showAccountRestoration}
          setIsShowAlert={setShowAccountRestoration}
          deleteID={getProfileDetailObj.data?.accountDeletion?.id}
        />
      )}

      <AppBar
        position="relative"
        sx={{
          backgroundImage: `url(${headerBackground})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        elevation={0}
      >
        <PCViewCenterBox>
          <AppBarContainer
            sx={{ maxWidth: theme.dimensions.PCMaxWidth }}
            paddingX={isMobile && "10px"}
          >
            <PaddedView multiples={2}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs={3}>
                  <KatchLogo color="white" />
                </Grid>
                <Grid
                  container
                  item
                  xs={9}
                  alignSelf="center"
                  textAlign="center"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {renderAuthInfo()}
                </Grid>
                {renderScreenOptionalAppBarContent()}
              </Grid>
            </PaddedView>
          </AppBarContainer>
        </PCViewCenterBox>
      </AppBar>
      {/* ====== Content Section ====== */}
      <ContentBox>
        <OverflowBox sx={{ overflowX: isMobile ? "hidden" : null, maxHeight: isMobile && "100%" }}>
          <MaxWidthBox
            sx={{
              maxWidth: theme.dimensions.PCMaxWidth,
            }}
          >
            <Outlet />
          </MaxWidthBox>
        </OverflowBox>
        <Footer />
      </ContentBox>
    </>
  );
}

export default CustomizedAppBar;
