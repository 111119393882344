import { Box, Divider, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import PaymentMethodCheckbox from "../../../components/forms/payment-method-checkbox.component";
import routes from "../../../components/navigation/routes";
import Loading from "../../../components/notification/backdrop-loading.component";
import InsufficientCreditModal from "../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import UnverifiedUserModal from "../../../components/notification/unverified-user-modal.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { isLogin } from "../../../infrastructure/utils";
import {
  createMembership,
  membershipSelector,
  previewMembership,
} from "../../../services/membership/membership-slice.service";
import {
  getMembershipPlans,
  membershipPlanSelector,
} from "../../../services/membership/plan/membership-plan-slice.service";
import { getProfileDetail } from "../../../services/profile/profile-slice.service";
import PreviewSummaryMembershipLoader from "../loader/preview-summary-membership-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required().label("Payment Method"),
});

function PreviewSummaryMembershipScreen() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { previewMembershipObj } = useSelector(membershipSelector);
  const { getMembershipPlansObj } = useSelector(membershipPlanSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);

  const handleFormSubmission = (values) => {
    const payloadValues = { ...values };
    setIsLoading(true);
    dispatch(createMembership(payloadValues)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (
          values.paymentMethod === "credits" ||
          payload.data.paymentTransaction.status === "paid"
        ) {
          dispatch(getProfileDetail());
          navigate(routes.MEMBERSHIP_PLAN_PAYMENT_SUCCESS, { state: { ...payload.data } });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmit = (values) => {
    if (values.paymentMethod === "credits" && !previewMembershipObj.data.creditPurchasable) {
      setShowInsufficientCreditModal(true);
      return;
    }

    handleFormSubmission(values);
  };

  useEffect(() => {
    dispatch(
      getMembershipPlans({
        businessId: params.get("businessId"),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        if (isLogin()) {
          dispatch(
            previewMembership({
              planId: params.get("planId"),
            }),
          );
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Box>
      {getMembershipPlansObj.status === "succeeded" &&
        getMembershipPlansObj.data.requireVerification && (
          <UnverifiedUserModal cancelAction={() => navigate(-1)} />
        )}

      {previewMembershipObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Membership Plan</TitleTypography>
        <Spacer size="l" />
        {getMembershipPlansObj.status === "succeeded" &&
        previewMembershipObj.status === "succeeded" ? (
          <Form
            innerRef={formRef}
            initialValues={{
              planId: params.get("planId"),
              paymentMethod: "credits",
              promoCode: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Grid
                        item
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <SectionTypography>Membership Plan Details</SectionTypography>
                        </Grid>
                      </Grid>
                      <Spacer size="m" />

                      <Grid container columnSpacing={2} alignContent="center">
                        <Grid
                          item
                          alignSelf="center"
                          paddingY="5px"
                          alignItems="center"
                          display="flex"
                          xs={4}
                        >
                          <img
                            src={getMembershipPlansObj.data?.imagePath}
                            alt="planImage"
                            width="100%"
                            height={isMobile ? 90 : 150}
                            style={{
                              borderRadius: `${theme.shape.borderRadius[2]}px`,
                              objectFit: "cover",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          display="grid"
                          flexDirection="column"
                          alignContent="center"
                          marginY="10px"
                          xs={8}
                        >
                          <Grid item>
                            <Grid item>
                              <Typography fontWeight="bold" className="planTitle">
                                {getMembershipPlansObj.data?.identifier}
                              </Typography>
                            </Grid>
                            <Spacer />
                            <Grid item display="flex" alignContent="center">
                              <Typography>
                                {getMembershipPlansObj.data?.shortDescription}
                              </Typography>
                            </Grid>
                            <Spacer />
                            <Grid item display="flex" alignContent="center">
                              <Spacer size="xs" position="left" />
                              <Typography fontWeight="bold" color="primary">
                                RM {getMembershipPlansObj.data?.price}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </PaddedView>
                  </SectionContainer>
                </Grid>

                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Grid
                        container
                        justifyContent="space-between"
                        display="flex"
                        alignItems="center"
                      >
                        <Grid item>
                          <SectionTypography>Payment Method</SectionTypography>
                        </Grid>
                      </Grid>
                      <Spacer size="m" />
                      <PaymentMethodCheckbox />
                    </PaddedView>
                  </SectionContainer>
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 5}>
                <SectionContainer item>
                  <PaddedView multiples={isMobile ? 3 : 4}>
                    <TitleTypography>Payment Summary</TitleTypography>
                    <Spacer size="m" />
                    <Grid
                      item
                      display="grid"
                      flexDirection="column"
                      alignContent="space-between"
                      minHeight="220px"
                    >
                      <Grid
                        container
                        item
                        dispaly="flex"
                        justifyContent="space-between"
                        rowSpacing={1}
                      >
                        <Grid item xs={6}>
                          <BodyTypography>Subtotal</BodyTypography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <BodyTypography>
                            RM {previewMembershipObj.data?.paymentSummary.subtotal}
                          </BodyTypography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid item>
                          <Spacer size="l" />
                          <Divider />
                          <Spacer size="l" />
                        </Grid>
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <SectionTypography>Total Price</SectionTypography>
                          <SectionTypography>
                            RM {previewMembershipObj.data.paymentSummary.totalPrice}
                          </SectionTypography>
                        </Grid>
                        <Spacer size="xl" />
                        <Grid item>
                          <FormSubmitButton width="100%" borderRadius={3}>
                            Confirm
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PaddedView>
                </SectionContainer>
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryMembershipLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default PreviewSummaryMembershipScreen;
