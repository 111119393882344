import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import Form from "../../../../../components/forms/form.component";
import PaymentMethodCheckbox from "../../../../../components/forms/payment-method-checkbox.component";
import routes from "../../../../../components/navigation/routes";
import Loading from "../../../../../components/notification/backdrop-loading.component";
import InsufficientCreditModal from "../../../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../components/utils/spacer.component";
import {
  createHourlyBooking,
  hourlyBookingSelector,
  previewAvailableHourlyBooking,
} from "../../../../../services/hourly-booking/hourly-booking-slice.service";
import { getProfileDetail } from "../../../../../services/profile/profile-slice.service";
import PromoSection from "../components/promo-section.component";
import PreviewSummaryLoader from "../loader/preview-summary-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required().label("Payment Method"),
  slots: Yup.array()
    .of(
      Yup.object().shape({
        startAt: Yup.date().required(),
        endAt: Yup.date().required(),
      }),
    )
    .required()
    .label("Slots"),
  promoCode: Yup.string().nullable().label("Promo Code"),
});

function PreviewSummaryScreen() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { previewAvailableHourlyBookingObj } = useSelector(hourlyBookingSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [redeemPromo, setRedeemPromo] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);

  const previewBooking = (values, promoRedeem, itemIndex) => {
    if (promoRedeem) {
      setRedeemPromo(true);
      setIsLoading(true);
    }

    dispatch(previewAvailableHourlyBooking(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsLoading(false);
        setRedeemSuccess(true);
      }

      if (meta.requestStatus === "rejected") {
        setIsLoading(false);
        const validPayload = { ...values };
        validPayload.slots[itemIndex].promoCode = "";
        formRef.current?.setFieldValue(`slots[${itemIndex}].promoCode`, "");
        previewBooking(validPayload, true, null);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleFormSubmission = (values) => {
    setIsLoading(true);
    dispatch(createHourlyBooking(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        if (
          values.paymentMethod === "credits" ||
          payload.data.paymentTransaction.status === "paid"
        ) {
          dispatch(getProfileDetail());
          navigate(routes.HOURLYBOOKINGPAYMENTSUCCESS, { state: { ...payload.data } });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmit = (values) => {
    if (
      values.paymentMethod === "credits" &&
      !previewAvailableHourlyBookingObj.data.creditPurchasable
    ) {
      setShowInsufficientCreditModal(true);
      return;
    }

    handleFormSubmission(values);
  };

  useEffect(() => {
    previewBooking(location.state, false, null);
  }, []);

  return (
    <Box>
      {previewAvailableHourlyBookingObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Booking Details</TitleTypography>
        <Spacer size="l" />
        {previewAvailableHourlyBookingObj.status === "succeeded" || redeemPromo ? (
          <Form
            innerRef={formRef}
            initialValues={{
              businessId: location.state.businessId,
              paymentMethod: "credits",
              slots: location.state.slots,
              promoCode: "",
              exclusiveReferralCode: location.state.exclusiveReferralCode,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Typography fontSize={theme.fonts.fontSizes.size14}>GYM SESSION</Typography>
                      <Spacer size="m" />
                      {previewAvailableHourlyBookingObj.data?.slots.map((item, index) => (
                        <Grid item key={uuidv4()}>
                          <Grid container justifyContent="space-between">
                            <TitleTypography>{item.business.name}</TitleTypography>
                          </Grid>
                          <Spacer />
                          <Grid item>
                            {item.memberPrice && (
                              <Grid item display="flex" alignContent="center">
                                <Typography fontWeight="bold" color="primary">
                                  RM {item.memberPrice} (MEMBER)
                                </Typography>
                              </Grid>
                            )}

                            <Grid item display="flex" alignContent="center">
                              <Typography
                                fontWeight="bold"
                                color={item.memberPrice ? "grey" : "primary"}
                              >
                                RM {item.price} {item.memberPrice && "(NON-MEMBER)"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Spacer />
                          <Grid item>
                            <BodyTypography>Date: {item.date}</BodyTypography>
                            <BodyTypography>
                              Time: {item.startTime} - {item.endTime}
                            </BodyTypography>
                            <BodyTypography>
                              Access Time: {item.accessTime} - {item.revokeTime}
                            </BodyTypography>
                          </Grid>
                          <Spacer size="m" />
                          <PromoSection previewBooking={previewBooking} itemIndex={index} />
                          <Spacer size="l" />
                        </Grid>
                      ))}
                    </PaddedView>
                  </SectionContainer>
                </Grid>

                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <SectionTypography>Payment Method</SectionTypography>
                      <Spacer size="m" />
                      <PaymentMethodCheckbox />
                    </PaddedView>
                  </SectionContainer>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <SectionContainer item>
                  <PaddedView multiples={isMobile ? 3 : 4}>
                    <TitleTypography>Payment Summary</TitleTypography>
                    <Spacer size="m" />
                    <Grid
                      item
                      display="grid"
                      flexDirection="column"
                      alignContent="space-between"
                      minHeight="220px"
                    >
                      <Grid
                        container
                        item
                        dispaly="flex"
                        justifyContent="space-between"
                        rowSpacing={1}
                      >
                        <Grid item xs={6}>
                          <BodyTypography>Subtotal</BodyTypography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <BodyTypography>
                            RM {previewAvailableHourlyBookingObj.data?.paymentSummary.subtotal}
                          </BodyTypography>
                        </Grid>

                        {previewAvailableHourlyBookingObj.data?.paymentSummary
                          .membershipDiscount !== "0.00" &&
                          redeemSuccess && (
                            <>
                              <Grid item xs={6}>
                                <BodyTypography>Membership Discount</BodyTypography>
                              </Grid>
                              <Grid item xs={6} textAlign="right">
                                <BodyTypography>
                                  -RM{" "}
                                  {
                                    previewAvailableHourlyBookingObj.data?.paymentSummary
                                      .membershipDiscount
                                  }
                                </BodyTypography>
                              </Grid>
                            </>
                          )}
                        {previewAvailableHourlyBookingObj.data?.paymentSummary.voucherDiscount !==
                          "0.00" &&
                          redeemSuccess && (
                            <>
                              <Grid item xs={6}>
                                <BodyTypography>Voucher Discount</BodyTypography>
                              </Grid>
                              <Grid item xs={6} textAlign="right">
                                <BodyTypography>
                                  -RM{" "}
                                  {
                                    previewAvailableHourlyBookingObj.data?.paymentSummary
                                      .voucherDiscount
                                  }
                                </BodyTypography>
                              </Grid>
                            </>
                          )}
                      </Grid>
                      <Grid item>
                        <HorizontalDivider spacerSize="l" />
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <SectionTypography>Total Price</SectionTypography>
                          <SectionTypography>
                            RM{" "}
                            {redeemSuccess
                              ? previewAvailableHourlyBookingObj.data?.paymentSummary.totalPrice
                              : previewAvailableHourlyBookingObj.data?.paymentSummary.subtotal}
                          </SectionTypography>
                        </Grid>
                        <Spacer size="xl" />
                        <Grid item>
                          <FormSubmitButton width="100%" borderRadius={3}>
                            Confirm
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PaddedView>
                </SectionContainer>
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default PreviewSummaryScreen;
